<template>
    <v-container style="max-width: 95%;">

        <v-row class="justify-center" style="display: grid;">
            <v-col
                cols="12"
                lg="12"
                md="12"
                sm="12"
            >
                <a href="/">
                    <v-img :src="require('@/assets/images/logo.png')" max-height="300" max-width="300" />
                </a>
            </v-col>

        </v-row>

        <!-- JOB INFORMATION -->
        <v-row>

            <v-col
                cols="12"
            >
                <JobInformation 
                    :id="serviceRequest.id"
                    :project="projectSelected"
                    :refreshJob="refreshJob"
                    :showJobSummary="true"
                    :showCustomerURL="false"
                    :showButtonsCreateEditProject="false"
                    :showButtonsCreateEditVersion="false"
                    :showClassification="false"
                    :showDisabledClassification="true"
                    :showQuickAccess="true"
                    :showQuickAccessAsDocument="true"
                    :showPayments="true"
                    v-on:changeProject="updateProject"
                />
            </v-col>
        </v-row>

        <!-- STAGES -->
        <v-row>
            <v-col
                cols="12"
                style="padding: 0px !important;"
            >
                <v-card-text>

                    <v-card
                        class="mx-auto"
                    >
                        <v-card-title class="text-h5 lighten-2" style="border-bottom: solid 1px var(--color__cinza)">
                            Stages
                        </v-card-title>
                        <v-card-text style="margin-top: 20px;">

                            <v-row>
                                <v-col
                                    cols="12"
                                    lg="12"
                                    md="12"
                                    sm="12"
                                    style="padding: 0px !important;"
                                >
                                    <v-stepper 
                                        alt-labels
                                        v-model="steps"
                                        style="box-shadow: none;"
                                    >
                                        <v-layout align-center justify-center>
                                            <v-row style="display: contents !important;">
                                                <v-col>
                                                    <v-stepper-header
                                                        style="box-shadow: none;height: 80px;"
                                                    >
                                                        <v-stepper-step
                                                            :complete="steps > 1"
                                                            step="1"
                                                            :edit-icon="steps > 1 ? 'mdi mdi-check' : 'mdi mdi-progress-check'"
                                                        >
                                                            Sales
                                                        </v-stepper-step>

                                                        <v-divider></v-divider>

                                                        <v-stepper-step
                                                            id="stepDrafting"
                                                            :complete="steps > 2"
                                                            step="2"
                                                            :edit-icon="steps > 2 ? 'mdi mdi-check' : 'mdi mdi-progress-check'"
                                                        >
                                                            Drafting
                                                        </v-stepper-step>

                                                        <v-divider></v-divider>

                                                        <v-stepper-step
                                                            id="stepPermitting"
                                                            :complete="steps > 3"
                                                            step="3"
                                                            :edit-icon="steps > 3 ? 'mdi mdi-check' : 'mdi mdi-progress-check'"
                                                        >
                                                            Permitting
                                                        </v-stepper-step>

                                                        <v-divider></v-divider>

                                                        <v-stepper-step 
                                                            id="stepConstruction"
                                                            :complete="steps > 4"
                                                            step="4"
                                                            :edit-icon="steps > 4 ? 'mdi mdi-check' : 'mdi mdi-progress-check'"
                                                        >
                                                            Construction
                                                        </v-stepper-step>
                                                    </v-stepper-header>
                                                </v-col>
                                            </v-row>
                                        </v-layout>


                                        <v-row>
                                            <v-col
                                                cols="12"
                                                lg="12"
                                                md="12"
                                                sm="12"
                                                :style="$vuetify.breakpoint.mobile ? 'display: flex; flex-direction: column; align-items: center;' : 'margin-left: 50px;'"
                                            >
                                                <!-- STEP 1: SALES  -->

                                                <v-timeline
                                                    v-if="showTimeline(1)"
                                                    align-top
                                                    dense
                                                    :style="this.$vuetify.breakpoint.mobile ? '' : 'margin-top: 30px;'"
                                                >
                                                    <v-timeline-item
                                                        :color="checkProgressTimeline(3, false, 0).color"
                                                        :icon="checkProgressTimeline(3, false, 0).icon"
                                                        fill-dot
                                                        small
                                                    >
                                                        <strong>In Progress</strong>
                                                    </v-timeline-item>

                                                    <v-timeline-item
                                                        :color="checkProgressTimeline(5, false, 0).color"
                                                        :icon="checkProgressTimeline(5, false, 0).icon"
                                                        fill-dot
                                                        small
                                                    >
                                                        <strong>Waiting Approval</strong>
                                                    </v-timeline-item>

                                                    <v-timeline-item
                                                        :color="checkProgressTimeline(6, false, 0).color"
                                                        :icon="checkProgressTimeline(6, false, 0).icon"
                                                        fill-dot
                                                        small
                                                    >
                                                        <strong>Finish Proposal</strong>
                                                    </v-timeline-item>

                                                    <v-timeline-item
                                                        :color="checkProgressTimeline(7, false, 0).color"
                                                        :icon="checkProgressTimeline(7, false, 0).icon"
                                                        fill-dot
                                                        small
                                                    >
                                                        <strong>Contracted</strong>
                                                    </v-timeline-item>
                                                </v-timeline>

                                                <!-- STEP 2: DRAFTING  -->

                                                <v-timeline
                                                    v-if="showTimeline(2)"
                                                    id="timelineDrafting"
                                                    align-top
                                                    dense
                                                    :style="this.$vuetify.breakpoint.mobile ? '' : 'margin-top: -215px;'"
                                                >
                                                    <v-timeline-item
                                                        :color="checkProgressTimeline(8, false, 0).color"
                                                        :icon="checkProgressTimeline(8, false, 0).icon"
                                                        fill-dot
                                                        small
                                                    >
                                                        <strong>Drafting Review</strong>
                                                    </v-timeline-item>

                                                    <v-timeline-item
                                                        :color="checkProgressTimeline(9, false, 0).color"
                                                        :icon="checkProgressTimeline(9, false, 0).icon"
                                                        fill-dot
                                                        small
                                                    >
                                                        <strong>Drafting</strong>
                                                    </v-timeline-item>

                                                    <v-timeline-item
                                                        :color="checkProgressTimeline(10, false, 0).color"
                                                        :icon="checkProgressTimeline(10, false, 0).icon"
                                                        fill-dot
                                                        small
                                                    >
                                                        <strong>Approvals</strong>
                                                    </v-timeline-item>

                                                    <v-timeline-item
                                                        :color="checkProgressTimeline(11, false, 0).color"
                                                        :icon="checkProgressTimeline(11, false, 0).icon"
                                                        fill-dot
                                                        small
                                                    >
                                                        <strong>Final Plan</strong>
                                                    </v-timeline-item>

                                                    <v-timeline-item
                                                        :color="checkProgressTimeline(12, false, 0).color"
                                                        :icon="checkProgressTimeline(12, false, 0).icon"
                                                        fill-dot
                                                        small
                                                    >
                                                        <strong>Engineering</strong>
                                                    </v-timeline-item>
                                                </v-timeline>

                                                <!-- STEP 3: PERMITTING  -->
                                                <v-timeline
                                                    v-if="showTimeline(3)"
                                                    id="timelinePermitting"
                                                    align-top
                                                    dense
                                                    :style="this.$vuetify.breakpoint.mobile ? '' : 'margin-top: -260px;'"
                                                >
                                                    <v-timeline-item
                                                        :color="checkProgressTimeline(14, false, 0).color"
                                                        :icon="checkProgressTimeline(14, false, 0).icon"
                                                        fill-dot
                                                        small
                                                    >
                                                        <strong>Pending</strong>
                                                    </v-timeline-item>

                                                    <v-timeline-item
                                                        :color="checkProgressTimeline(15, false, 0).color"
                                                        :icon="checkProgressTimeline(15, false, 0).icon"
                                                        fill-dot
                                                        small
                                                    >
                                                        <strong>Under Review</strong>
                                                    </v-timeline-item>

                                                    <v-timeline-item
                                                        :color="checkProgressTimeline(16, false, 0).color"
                                                        :icon="checkProgressTimeline(16, false, 0).icon"
                                                        fill-dot
                                                        small
                                                    >
                                                        <strong>Ongoing</strong>
                                                    </v-timeline-item>

                                                    <v-timeline-item
                                                        :color="checkProgressTimeline(17, false, 0).color"
                                                        :icon="checkProgressTimeline(17, false, 0).icon"
                                                        fill-dot
                                                        small
                                                    >
                                                        <strong>Completed</strong>
                                                    </v-timeline-item>
                                                </v-timeline>

                                                <!-- STEP 4: CONSTRUCTION -->

                                                <v-timeline
                                                    v-if="showTimeline(4)"
                                                    id="timelineConstruction"
                                                    :style="this.$vuetify.breakpoint.mobile ? '' : 'width: 400px; margin-top: -215px;'"
                                                >
                                                    <v-timeline-item
                                                        class="text-right"
                                                        :color="checkProgressTimeline(21, true, 1).color"
                                                        :icon="checkProgressTimeline(21, true, 1).icon"
                                                        fill-dot
                                                        small
                                                    >
                                                        <template v-slot:opposite>
                                                            <strong>Pre-Dig</strong>
                                                        </template>
                                                    </v-timeline-item>

                                                    <v-timeline-item
                                                        class="text-right"
                                                        :color="checkProgressTimeline(27, true, 2).color"
                                                        :icon="checkProgressTimeline(27, true, 2).icon"
                                                        fill-dot
                                                        small
                                                    >
                                                        <strong>Front end</strong>
                                                    </v-timeline-item>

                                                    <v-timeline-item
                                                        class="text-right"
                                                        :color="checkProgressTimeline(31, true, 3).color"
                                                        :icon="checkProgressTimeline(31, true, 3).icon"
                                                        fill-dot
                                                        small
                                                    >
                                                        <template v-slot:opposite>
                                                            <strong>Grading / Plumbing</strong>
                                                        </template>
                                                    </v-timeline-item>

                                                    <v-timeline-item
                                                        class="text-right"
                                                        :color="checkProgressTimeline(37, true, 4).color"
                                                        :icon="checkProgressTimeline(37, true, 4).icon"
                                                        fill-dot
                                                        small
                                                    >
                                                        <strong>Brick & Tile / Deck</strong>
                                                    </v-timeline-item>

                                                    <v-timeline-item
                                                        class="text-right"
                                                        :color="checkProgressTimeline(41, true, 5).color"
                                                        :icon="checkProgressTimeline(41, true, 5).icon"
                                                        fill-dot
                                                        small
                                                    >
                                                        <template v-slot:opposite>
                                                            <strong>Equipment / Electric</strong>
                                                        </template>
                                                    </v-timeline-item>

                                                    <v-timeline-item
                                                        class="text-right"
                                                        :color="checkProgressTimeline(46, true, 6).color"
                                                        :icon="checkProgressTimeline(46, true, 6).icon"
                                                        fill-dot
                                                        small
                                                    >
                                                        <strong>Plaster</strong>
                                                    </v-timeline-item>

                                                    <v-timeline-item
                                                        class="text-right"
                                                        :color="checkProgressTimeline(46, true, 7).color"
                                                        :icon="checkProgressTimeline(46, true, 7).icon"
                                                        fill-dot
                                                        small
                                                    >
                                                        <template v-slot:opposite>
                                                            <strong>Fence</strong>
                                                        </template>
                                                    </v-timeline-item>

                                                    <v-timeline-item
                                                        class="text-right"
                                                        :color="checkProgressTimeline(46, true, 8).color"
                                                        :icon="checkProgressTimeline(46, true, 8).icon"
                                                        fill-dot
                                                        small
                                                    >
                                                        <strong>BBQ</strong>
                                                    </v-timeline-item>

                                                    <v-timeline-item
                                                        class="text-right"
                                                        :color="checkProgressTimeline(46, true, 9).color"
                                                        :icon="checkProgressTimeline(46, true, 9).icon"
                                                        fill-dot
                                                        small
                                                    >
                                                        <template v-slot:opposite>
                                                            <strong>Pergola</strong>
                                                        </template>
                                                    </v-timeline-item>
                                                </v-timeline>
                                            </v-col>
                                        </v-row>

                                    </v-stepper>
                                
                                </v-col>
                            </v-row>
                        </v-card-text>

                    </v-card>
                    
                </v-card-text>
            </v-col>
        </v-row>

        <!-- CONSUMER RESPONSIBILITIES -->
        <v-row>

            <v-col
                cols="12"
                style="padding: 0px !important;"
            >
                <v-card-text>

                    <v-card
                        class="mx-auto"
                    >
                        <v-card-title class="text-h5 lighten-2" style="border-bottom: solid 1px var(--color__cinza)">
                            Customer Responsibilities
                        </v-card-title>
                        <v-card-text style="margin-top: 20px;">

                            <v-row>
                                <v-col 
                                    cols="12"
                                    lg="5"
                                    md="12"
                                    sm="12"
                                >
                                    <p v-html="consumerRespDescription"></p>
                                </v-col>
                            </v-row> 
                        </v-card-text>

                    </v-card>
                    
                </v-card-text>
            </v-col>
        </v-row>

        <!-- COMMUNICATIONS -->
        <v-row>

            <v-col
                cols="12"
                style="padding: 0px !important;"
            >
                <v-card-text>

                    <v-card
                        class="mx-auto"
                    >
                        <v-card-title class="text-h5 lighten-2" style="border-bottom: solid 1px var(--color__cinza)">
                            Communications
                        </v-card-title>
                        <v-card-text style="margin-top: 20px;">

                            <v-row
                                v-if="listCommunication.length <= 0"
                            >
                                <v-col
                                    cols="12"
                                    lg="12"
                                    md="12"
                                    sm="12"
                                    style="text-align: center;"
                                >
                                    <span>In this section you will see any important warning from related to your project Van Kirk staff</span>
                                </v-col>
                            </v-row>

                            <v-row
                                v-else
                            >
                                <v-col
                                    cols="12"
                                    lg="12"
                                    md="12"
                                    sm="12"
                                    style="text-align: center;"
                                >
                                    <table style="border: none !important; width: 100%; font-size: 13px;">
                                        <tr v-for="(item, index) in listCommunication" :key="index">
                                            <td style="width: 30px; padding: 10px;"><v-icon :color="item.classification == 1 ? 'var(--color__red)' : 'var(--color__alert)'">mdi mdi-bell-alert-outline</v-icon></td>
                                            <td v-if="item.hasPictures == true" style="width: 30px; text-align: left; padding: 10px;'">
                                                <v-tooltip top>
                                                    <template v-slot:activator="{ on }">
                                                        <v-icon color="primary" v-on="on" @click="showPhotos(item)">mdi mdi-image-multiple</v-icon>
                                                    </template>
                                                    <span>Click to view the photos</span>
                                                </v-tooltip>
                                            </td>
                                            <td style="text-align: center; width: 200px; font-weight: bold; padding: 10px;">{{ item.profileName }} / {{ item.userName }}</td>
                                            <td :style="item.classification == 1 ? 'text-align: left; color: var(--color__red) !important; padding: 10px;' : 'text-align: left; padding: 10px;'">{{ item.description }}</td>
                                        </tr>
                                    </table>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col
                                    cols="12"
                                    lg="12"
                                    md="12"
                                    sm="12"
                                >
                                
                                </v-col>
                            </v-row>
                        </v-card-text>

                    </v-card>
                    
                </v-card-text>
            </v-col>
        </v-row>

        <!-- PROMOTIONS -->
        <v-row>

            <v-col
                cols="12"
                style="padding: 0px !important;"
            >
                <v-card-text>

                    <v-card
                        class="mx-auto"
                    >
                        <v-card-title class="text-h5 lighten-2" style="border-bottom: solid 1px var(--color__cinza)">
                            Promotions
                        </v-card-title>
                        <v-card-text style="margin-top: 20px;">

                            <v-row
                                v-if="listPromotions.length <= 0"
                            >
                                <v-col
                                    cols="12"
                                    lg="12"
                                    md="12"
                                    sm="12"
                                    style="text-align: center;"
                                >
                                    <span>In this section you will find relevant promotions for you project</span>
                                </v-col>
                            </v-row>
                            <v-row v-else>
                                <v-col
                                    cols="12"
                                    lg="3"
                                    md="4"
                                    sm="12"
                                    v-for="(itemPromotion, index) in listPromotions" :key="index"
                                    style="margin-bottom: 20px;"
                                >
                                    <v-card
                                        elevation="10"
                                        max-width="350"
                                    >
                                        <v-carousel
                                            cycle
                                            :show-arrows="false"
                                            show-arrows-on-hover
                                            height="250"
                                        >
                                            <v-carousel-item
                                                v-for="(itemImage, i) in itemPromotion.listImages"
                                                :key="i"
                                            >
                                                <v-sheet
                                                    height="100%"
                                                    tile
                                                >
                                                    <v-img
                                                        :src="itemImage.src"
                                                        :lazy-src="itemImage.src"
                                                        aspect-ratio="1"
                                                    >
                                                        <template v-slot:placeholder>
                                                            <v-row
                                                                class="fill-height ma-0"
                                                                align="center"
                                                                justify="center"
                                                            >
                                                                <v-progress-circular
                                                                    indeterminate
                                                                    color="grey lighten-5"
                                                                ></v-progress-circular>
                                                            </v-row>
                                                        </template>
                                                    </v-img>
                                                </v-sheet>
                                            </v-carousel-item>
                                        </v-carousel>
                                        <v-list two-line>
                                            <v-list-item>
                                                <v-list-item-content>
                                                    <v-list-item-title>{{ itemPromotion.title }}</v-list-item-title>
                                                    <v-list-item-subtitle>{{ itemPromotion.description }}</v-list-item-subtitle>
                                                    <v-list-item-subtitle 
                                                        v-if="itemPromotion.link != null && itemPromotion.link != undefined && itemPromotion.link != ''"
                                                        style="text-align: center; margin-top: 10px;"
                                                    >
                                                        <v-chip 
                                                            link
                                                            label
                                                            color="var(--color__main)"
                                                            style="color: white;"
                                                            @click="openLink(itemPromotion.link)"
                                                        >
                                                            More Details
                                                        </v-chip>
                                                    </v-list-item-subtitle>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-list>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>                    
                </v-card-text>
            </v-col>
        </v-row>

        <!-- CONTACTS -->
        <v-row>

            <v-col
                cols="12"
                style="padding: 0px !important;"
            >
                <v-card-text>

                    <v-card
                        class="mx-auto"
                    >
                        <v-card-title class="text-h5 lighten-2" style="border-bottom: solid 1px var(--color__cinza)">
                            Contacts
                        </v-card-title>
                        <v-card-text style="margin-top: 20px;">

                            <v-row>
                                <v-col 
                                    cols="12"
                                    lg="5"
                                    md="12"
                                    sm="12"
                                >
                                    <label>Sales Person</label>
                                    <table style="width: 100%; border: none; margin-top: 10px;">
                                        <tr><td>{{ getSalesPerson.name }}</td></tr>
                                        <tr><td>{{ getSalesPerson.email }}</td></tr>
                                        <tr><td>{{ getSalesPerson.phone }}</td></tr>
                                    </table>
                                </v-col>
                                <v-col
                                    style="text-align: center;"
                                    cols="12"
                                    lg="2"
                                    md="12"
                                    sm="12"
                                >
                                
                                    <v-divider vertical></v-divider>

                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="5"
                                    md="12"
                                    sm="12"
                                >
                                    <label>Company</label>
                                    <table style="width: 100%; border: none; margin-top: 10px;">
                                        <tr><td>Van Kirk & Sons Pools & Spas</td></tr>
                                        <tr><td>+1 954-755-4402</td></tr>
                                        <tr><td>3144 SW 13th Drive</td></tr>
                                        <tr><td>Deerfield Beach, FL 33442</td></tr>
                                        <tr><td>Licensed & Insured | CPC: 04956 / CPC: 1456646</td></tr>
                                    </table>
                                </v-col>
                            </v-row> 
                        </v-card-text>

                    </v-card>
                    
                </v-card-text>
            </v-col>
        </v-row>

        <ShowFiles 
            :showFiles.sync="dialogShowFiles.show"
            :showAsCarrousel.sync="dialogShowFiles.showAsCarrousel"
            :reload="dialogShowFiles.reload"
            :listParams="dialogShowFiles.listParams"
            :showFilters="dialogShowFiles.showFilters"
        />

    </v-container>
</template>

<script>
    import Helpers from '@/utilities/Helpers';
    import JobInformation from "@/components/Services/JobInformation";
    import ShowFiles from "@/components/File/ShowFiles";
    import { 
        TYPE_DOCUMENT, 
        TYPE_ATTACHMENT, 
        PROJECT_STATUS,
        CONSTRUCTION_PANEL,
        CONSTRUCTION_STAGE
    } from "@/utilities/Enums";
    import * as moment from 'moment';
    import Vue from "vue";

    export default ({

        mixins: [Helpers],

        components: {
            JobInformation,
            ShowFiles
        },

        props: {
            guid: {
                type: String,
                default: ""
            }
        },

        data: () => ({

            serviceRequest: {
                id: 0,
                dateRegister: 0,
                idUserRegister: 0,
                idKindWork: 3,
                isSurvey: 1,
                isCustomer: 0,
                customerSelected: {
                    name: ""
                },
                addressSelected: {
                    address: "",
                    cityDescription: "",
                    zipCode: "",
                    isGatedCommunityDescription: "",
                    gatedCommunity: "",
                    communityDetails: "",
                    gateCode: "",
                    dogOnSiteDescription: "",
                },
                phone: "",
                email: "",
                notes: "",
                appointmentAddress: "",
                appointmentCitySelected: {
                    codeStateDescriptionCity: "",
                    stateSelected: {
                        code: ""
                    }
                },
                status: "",
            },

            steps: 3,

            listProjects: [],
            projectSelected: null,
            refreshJob: false,

            listCommunication: [],
            listPromotions: [],

            listConsumerResp: [],
            consumerRespDescription: '',
            
            dialogShowFiles: {
                show: false,
                showAsCarrousel: true,
                reload: 0,
                listParams: [],
                showFilters: false
            },

            PROJECT_STATUS,
            CONSTRUCTION_PANEL,
            CONSTRUCTION_STAGE
        }),

        computed: {
            getSalesPerson() {
                let salesPerson = {
                    name: '',
                    email: '',
                    phone: ''
                };

                if (this.serviceRequest.userResponsibleSelected != null && this.serviceRequest.userResponsibleSelected != undefined) {
                    salesPerson.name = this.serviceRequest.userResponsibleSelected.name;
                    salesPerson.email = this.serviceRequest.userResponsibleSelected.email;
                    salesPerson.phone = this.serviceRequest.userResponsibleSelected.phone;
                }

                return salesPerson;
            }
        },

        methods: {

            showTimeline(step) {

                if (this.$vuetify.breakpoint.mobile == true) {

                    if (this.steps == step) {
                        return true;
                    }
                    else {
                        return false;
                    }
                }
                else {
                    return true;
                }
            },

            checkProgressTimeline(status, construction = true, phase = 0) {
                let colorAndIcon = {
                    color: 'var(--color__silver)',
                    icon: 'mdi-progress-check'
                }

                if (this.projectSelected != null && this.projectSelected != null) {

                    if (construction) {
                        if (phase == this.CONSTRUCTION_PANEL.PREDIG) {
                            
                            if (this.projectSelected.status >= this.PROJECT_STATUS.CONSTRUCTION_PREDIG_INCOMING_PROJECTS.id && this.projectSelected.status <= this.PROJECT_STATUS.CONSTRUCTION_PREDIG_REBAR.id) {
                                colorAndIcon.color = "var(--color__status_pending)";
                                colorAndIcon.icon = "mdi-account-badge-outline";
                            }
                            else if (this.projectSelected.status > status) {
                                colorAndIcon.color = "var(--color__completed)";
                                colorAndIcon.icon = "mdi-check";
                            }
                        }

                        if (phase == this.CONSTRUCTION_PANEL.FRONTEND) {
                            
                            if (this.projectSelected.status >= this.PROJECT_STATUS.CONSTRUCTION_FRONTEND_DIGDEMO.id && this.projectSelected.status <= this.PROJECT_STATUS.CONSTRUCTION_FRONTEND_BACKFILL.id) {
                                colorAndIcon.color = "var(--color__status_pending)";
                                colorAndIcon.icon = "mdi-account-badge-outline";
                            }
                            else if (this.projectSelected.status > status) {
                                colorAndIcon.color = "var(--color__completed)";
                                colorAndIcon.icon = "mdi-check";
                            }
                        }

                        if (phase == this.CONSTRUCTION_PANEL.GRADINGPLUMBING) {
                            
                            if (this.projectSelected.status >= this.PROJECT_STATUS.CONSTRUCTION_GRADINGPLUMBING_PLUMBING.id && this.projectSelected.status <= this.PROJECT_STATUS.CONSTRUCTION_GRADINGPLUMBING_DECKBONDINSPECTION.id) {
                                colorAndIcon.color = "var(--color__status_pending)";
                                colorAndIcon.icon = "mdi-account-badge-outline";
                            }
                            else if (this.projectSelected.status > status) {
                                colorAndIcon.color = "var(--color__completed)";
                                colorAndIcon.icon = "mdi-check";
                            }
                        }

                        if (phase == this.CONSTRUCTION_PANEL.BRICKTILE) {
                            
                            if (this.projectSelected.status >= this.PROJECT_STATUS.CONSTRUCTION_BRICKTILE_BRICKANDTILEINSTALL.id && this.projectSelected.status <= this.PROJECT_STATUS.CONSTRUCTION_BRICKTILE_DECKINSPECTION.id) {
                                colorAndIcon.color = "var(--color__status_pending)";
                                colorAndIcon.icon = "mdi-account-badge-outline";
                            }
                            else if (this.projectSelected.status > status) {
                                colorAndIcon.color = "var(--color__completed)";
                                colorAndIcon.icon = "mdi-check";
                            }
                        }

                        if (phase == this.CONSTRUCTION_PANEL.EQUIPMENTELETRIC) {
                            
                            if (this.projectSelected.status >= this.PROJECT_STATUS.CONSTRUCTION_EQUIPMENTELETRIC_EQUIPMENTSET.id && this.projectSelected.status <= this.PROJECT_STATUS.CONSTRUCTION_EQUIPMENTELETRIC_INSPECTION.id) {
                                colorAndIcon.color = "var(--color__status_pending)";
                                colorAndIcon.icon = "mdi-account-badge-outline";
                            }
                            else if (this.projectSelected.status > status) {
                                colorAndIcon.color = "var(--color__completed)";
                                colorAndIcon.icon = "mdi-check";
                            }
                        }

                        if (phase == this.CONSTRUCTION_PANEL.PLASTER) {
                            
                            if (this.projectSelected.status >= this.PROJECT_STATUS.CONSTRUCTION_PLASTE_FINALWALKTHROUGHT.id && this.projectSelected.status <= this.PROJECT_STATUS.CONSTRUCTION_PLASTE_OPENITEMS.id) {
                                colorAndIcon.color = "var(--color__status_pending)";
                                colorAndIcon.icon = "mdi-account-badge-outline";
                            }
                            else if (this.projectSelected.status > status) {
                                colorAndIcon.color = "var(--color__completed)";
                                colorAndIcon.icon = "mdi-check";
                            }
                        }
                    }
                    else {

                        if (this.projectSelected.status == status) {
                            if (this.projectSelected.situation == 2) {
                                colorAndIcon.color = "var(--color__red)";
                                colorAndIcon.icon = "mdi-alert";
                            }
                            else if (this.projectSelected.situation == 3) {
                                colorAndIcon.color = "var(--color__red)";
                                colorAndIcon.icon = "mdi-block";
                            }
                            else {
                                colorAndIcon.color = "var(--color__status_pending)";
                                colorAndIcon.icon = "mdi-account-badge-outline";
                            }
                        }
                        else if (this.projectSelected.status > status) {
                            colorAndIcon.color = "var(--color__completed)";
                            colorAndIcon.icon = "mdi-check";
                        }
                    }

                    if (colorAndIcon.color == 'var(--color__status_pending)') {
                        let idStage = this.getStageByStatys(this.projectSelected.status);

                        let listConsumerRespFiltered = this.listConsumerResp.filter(con => con.idStage == idStage)[0];

                        if (listConsumerRespFiltered != null && listConsumerRespFiltered != undefined) {
                            this.consumerRespDescription = listConsumerRespFiltered.description.toString().replaceAll('\n', '<br />');
                        }
                    }

                }

                return colorAndIcon;
            },
            
            async getRegister() {

                if (this.guid != undefined && this.guid != null && this.guid != "") {

                    let response = await this.$store.dispatch("serviceModule/GetByGuid", this.guid);

                    this.serviceRequest = response.result;

                    this.listConsumerResp = await this.$store.dispatch("consumerRespModule/List");
                }
            },

            async getIDProject() {

                let idServiceProject = 0;

                if (this.projectSelected != null && this.projectSelected != null) {
                    idServiceProject = this.projectSelected.id;
                }

                return idServiceProject;
            },

            getStageByStatys(idStatus) {
                if (idStatus == this.PROJECT_STATUS.CONSTRUCTION_PREDIG_PRESITE.id) return this.CONSTRUCTION_STAGE.PREDIG_PRESITE
                if (idStatus == this.PROJECT_STATUS.CONSTRUCTION_PREDIG_LOCALES.id) return this.CONSTRUCTION_STAGE.PREDIG_LOCALES
                if (idStatus == this.PROJECT_STATUS.CONSTRUCTION_PREDIG_PRESITE_RESP.id) return this.CONSTRUCTION_STAGE.PREDIG_PRESITE_RESP
                if (idStatus == this.PROJECT_STATUS.CONSTRUCTION_PREDIG_REBAR.id) return this.CONSTRUCTION_STAGE.PREDIG_REBAR
                
                if (idStatus == this.PROJECT_STATUS.CONSTRUCTION_FRONTEND_DIGDEMO.id) return this.CONSTRUCTION_STAGE.FRONTEND_DIGDEMO
                if (idStatus == this.PROJECT_STATUS.CONSTRUCTION_FRONTEND_FORMSTEEL.id) return this.CONSTRUCTION_STAGE.FRONTEND_FORMSTEEL
                if (idStatus == this.PROJECT_STATUS.CONSTRUCTION_FRONTEND_FRONTENDINSPECTION.id) return this.CONSTRUCTION_STAGE.FRONTEND_FRONTENDINSPECTION
                if (idStatus == this.PROJECT_STATUS.CONSTRUCTION_FRONTEND_PREPLUMB.id) return this.CONSTRUCTION_STAGE.FRONTEND_PREPLUMB
                if (idStatus == this.PROJECT_STATUS.CONSTRUCTION_FRONTEND_GUNITE.id) return this.CONSTRUCTION_STAGE.FRONTEND_GUNITE
                if (idStatus == this.PROJECT_STATUS.CONSTRUCTION_FRONTEND_BACKFILL.id) return this.CONSTRUCTION_STAGE.FRONTEND_BACKFILL
                
                if (idStatus == this.PROJECT_STATUS.CONSTRUCTION_GRADINGPLUMBING_PLUMBING.id) return this.CONSTRUCTION_STAGE.GRADINGPLUMBING_PLUMBING
                if (idStatus == this.PROJECT_STATUS.CONSTRUCTION_GRADINGPLUMBING_PLUMBING.idINSPECTION) return this.CONSTRUCTION_STAGE.GRADINGPLUMBING_PLUMBINGINSPECTION
                if (idStatus == this.PROJECT_STATUS.CONSTRUCTION_GRADINGPLUMBING_PATIOGRADE.id) return this.CONSTRUCTION_STAGE.GRADINGPLUMBING_PATIOGRADE
                if (idStatus == this.PROJECT_STATUS.CONSTRUCTION_GRADINGPLUMBING_DECKBONDINSPECTION.id) return this.CONSTRUCTION_STAGE.GRADINGPLUMBING_BONDINGGRID
                
                if (idStatus == this.PROJECT_STATUS.CONSTRUCTION_BRICKTILE_BRICKANDTILEINSTALL.id) return this.CONSTRUCTION_STAGE.BRICKTILE_SENDDELIVERY
                if (idStatus == this.PROJECT_STATUS.CONSTRUCTION_BRICKTILE_DECKINSTALL.id) return this.CONSTRUCTION_STAGE.BRICKTILE_DECKINSTALL
                if (idStatus == this.PROJECT_STATUS.CONSTRUCTION_BRICKTILE_FINALGRADE.id) return this.CONSTRUCTION_STAGE.BRICKTILE_FINALGRADE
                if (idStatus == this.PROJECT_STATUS.CONSTRUCTION_BRICKTILE_CLEANUP.id) return this.CONSTRUCTION_STAGE.BRICKTILE_CLEANUP
                if (idStatus == this.PROJECT_STATUS.CONSTRUCTION_BRICKTILE_DECKINSPECTION.id) return this.CONSTRUCTION_STAGE.BRICKTILE_DECKINSPECTION
                
                if (idStatus == this.PROJECT_STATUS.CONSTRUCTION_EQUIPMENTELETRIC_EQUIPMENTSET.id) return this.CONSTRUCTION_STAGE.EQUIPMENTELETRIC_EQUIPMENTSET
                if (idStatus == this.PROJECT_STATUS.CONSTRUCTION_EQUIPMENTELETRIC_ELECTRICHOOKUP.id) return this.CONSTRUCTION_STAGE.EQUIPMENTELETRIC_ELECTRICHOOKUP
                if (idStatus == this.PROJECT_STATUS.CONSTRUCTION_EQUIPMENTELETRIC_PREPLASTER.id) return this.CONSTRUCTION_STAGE.EQUIPMENTELETRIC_PREPLASTER
                if (idStatus == this.PROJECT_STATUS.CONSTRUCTION_EQUIPMENTELETRIC_INSPECTION.id) return this.CONSTRUCTION_STAGE.EQUIPMENTELETRIC_INSPECTION
                
                if (idStatus == this.PROJECT_STATUS.CONSTRUCTION_PLASTE_FINALWALKTHROUGHT.id) return this.CONSTRUCTION_STAGE.PLASTE_FINALWALKTHROUGHT
                if (idStatus == this.PROJECT_STATUS.CONSTRUCTION_PLASTE_VKBOWLDELIVERY.id) return this.CONSTRUCTION_STAGE.PLASTE_VKBOWLDELIVERY
                if (idStatus == this.PROJECT_STATUS.CONSTRUCTION_PLASTE_PREP.id) return this.CONSTRUCTION_STAGE.PLASTE_PREP
                if (idStatus == this.PROJECT_STATUS.CONSTRUCTION_PLASTE_PLASTER.id) return this.CONSTRUCTION_STAGE.PLASTE_PLASTER
                if (idStatus == this.PROJECT_STATUS.CONSTRUCTION_PLASTE_OPENITEMS.id) return this.CONSTRUCTION_STAGE.PLASTE_OPENITEMS
                
                if (idStatus == this.PROJECT_STATUS.CONSTRUCTION_SERVICE_FIREUP.id) return this.CONSTRUCTION_STAGE.SERVICE_FIREUP
                if (idStatus == this.PROJECT_STATUS.CONSTRUCTION_SERVICE_AFTERPLASTER.id) return this.CONSTRUCTION_STAGE.SERVICE_AFTERPLASTER
                if (idStatus == this.PROJECT_STATUS.CONSTRUCTION_SERVICE_TURNOVER.id) return this.CONSTRUCTION_STAGE.SERVICE_TURNOVER
                if (idStatus == this.PROJECT_STATUS.CONSTRUCTION_SERVICE_INSPECTION.id) return this.CONSTRUCTION_STAGE.SERVICE_INSPECTION
            },

            async updateProject(projectSelected, listProjects) {

                this.showLoading();

                this.projectSelected = projectSelected;
                this.listProjects = listProjects;

                if (this.projectSelected != null && this.projectSelected != undefined) {
                    if (this.projectSelected.status >= 0 && this.projectSelected.status <= this.PROJECT_STATUS.SALES_CONTRACTED) {
                        this.steps = 1;
                    }
                    else if (this.projectSelected.status >= this.PROJECT_STATUS.DRAFTING_REVIEW && this.projectSelected.status <= this.PROJECT_STATUS.DRAFTING_NEEDS_APPROVAL) {
                        this.steps = 2;
                    }
                    else if (this.projectSelected.status >= this.PROJECT_STATUS.PERMITTING_PENDING && this.projectSelected.status <= this.PROJECT_STATUS.PERMITTING_ONGOING) {
                        this.steps = 3;
                    }
                    else if (this.projectSelected.status >= this.PROJECT_STATUS.CONSTRUCTION_PREDIG_INCOMING_PROJECTS.id && this.projectSelected.status <= this.PROJECT_STATUS.CONSTRUCTION_PLASTE_OPENITEMS.id) {
                        this.steps = 4;
                    }
                    else {
                        this.steps = 5;
                    }

                    let listPromotions = await this.$store.dispatch("promotionModule/ListValidPromotion");

                    if (this.steps <= 3) {
                        this.listPromotions = listPromotions.filter(prm => prm.phaseToShowSelected.phaseToShow == 0)
                    }
                    else if (this.steps == 4) {
                        this.listPromotions = listPromotions.filter(prm => prm.phaseToShowSelected.phaseToShow == 1)
                    }
                    else if (this.steps == 5) {
                        this.listPromotions = listPromotions.filter(prm => prm.phaseToShowSelected.phaseToShow == 2)
                    }

                    await this.listPromotions.forEach(async itemPromotion => {

                        let listAttachments = await this.$store.dispatch("attachmentModule/GetAllByDocument", { 
							typeAttachment: TYPE_ATTACHMENT.PROMOTION.value, 
							typeDocument: TYPE_DOCUMENT.PROMOTION.value, 
							idKey: itemPromotion.id
						});

                        itemPromotion.listImages = [];
						
						listAttachments.forEach(itemPhoto => {

                            itemPromotion.listImages.push({
                                id: itemPhoto.id,
                                fileName: itemPhoto.fileName,
                                fileType: itemPhoto.fileType,
                                createdOn: itemPhoto.dateRegisterFormatted,
                                userName: itemPhoto.userNameRegister,
                                src: `data:image/jpg;base64,${itemPhoto.file}`
                            })

                            this.$forceUpdate();
						})
                    });

                    let idServiceProject = await this.getIDProject();

                    let listNote = await this.$store.dispatch("serviceModule/ListProjectNote", { 
                        idService: 0, 
                        idServiceProject, 
                        onlyCheckExists: false, 
                        idStatus: 0, 
                        idPanel: 0, 
                        idStage: 0, 
                        idUser: 0 
                    });

                    let today = moment().clone().startOf('month').format('YYYY-MM-DD');

                    this.listCommunication = listNote.filter(not => not.showToCustomer == 1 && not.expirationDate >= today);

                    this.listCommunication.forEach(async item => {

                        let listAttachments = await this.$store.dispatch("attachmentModule/GetAllByDocument", { 
							typeAttachment: TYPE_ATTACHMENT.PROJECT.value, 
							typeDocument: TYPE_DOCUMENT.NOTE.value, 
							idKey: item.id
						});

                        item.hasPictures = listAttachments != null && listAttachments != undefined && listAttachments.id != 0 ? true : false;

                        this.$forceUpdate();
                    });
                }

                this.hideLoading();
            },

            async revalidateGuid() {

                try {
                    let response = await Vue.prototype.$vanKirkApi.get(`service/validate-guid/${this.guid}`,
                    {
                        headers: {
                            "content-type": "application/json"
                        }
                    });

                    if (response.status !== 200) {
                        this.showToast("error", "Warning!", "Invalid token!");
                        this.$router.push({ path: "/login" });
                    }
                    else {
                        this.getRegister();
                    }
                }
                catch {
                    this.showToast("error", "Warning!", "Invalid token!");
                    this.$router.push({ path: "/login" });
                }
            },

            adjustTimeline() {

                const stepDrafting = document.getElementById('stepDrafting').getBoundingClientRect();
                const timelineDrafting = document.getElementById('timelineDrafting');

                const stepPermitting = document.getElementById('stepPermitting').getBoundingClientRect();
                const timelinePermitting = document.getElementById('timelinePermitting');

                const stepConstruction = document.getElementById('stepConstruction').getBoundingClientRect();
                const timelineConstruction = document.getElementById('timelineConstruction');

                const screenWidth = window.innerWidth;

                if (stepDrafting != null && stepDrafting != undefined &&
                    timelineDrafting != null && timelineDrafting != undefined && 
                    stepPermitting != null && stepPermitting != undefined &&
                    timelinePermitting != null && timelinePermitting != undefined) {

                    if (screenWidth > 1000) {
                        const draftingLeft = stepDrafting.left - 80;
                        const permittingLeft = stepPermitting.left - 80;
                        const constructionLeft = stepConstruction.left - 230;

                        timelineDrafting.style.left = `${draftingLeft}px`;
                        timelinePermitting.style.left = `${permittingLeft}px`;
                        timelineConstruction.style.left = `${constructionLeft}px`;
                    }
                    else {
                        timelineDrafting.style.left = `${stepDrafting.left - 5}px`;
                        timelinePermitting.style.left = `${stepPermitting.left - 5}px`;
                        timelineConstruction.style.left = `${stepConstruction.left - 160}px`;
                    }
                }
            },

            openLink(url) {
                window.open(url);
            },

            async showPhotos(note) {
                
                let listParams = [];
                let stageDescription = '';

                if (note != null && note != undefined) {
                    stageDescription = note.statusDescription;
                }

                listParams.push({
                    stageDescription: stageDescription,
                    typeAttachment: TYPE_ATTACHMENT.PROJECT.value,
                    typeDocument: TYPE_DOCUMENT.NOTE.value,
                    typeAttachmentDescription: '',
                    typeDocumentDescription: '',
                    idKey: note.id,
                    isImage: true
                });

                this.dialogShowFiles = {
                    show: true,
                    showAsCarrousel: true,
                    reload: Math.random(),
                    listParams,
                    showFilters: false
                };
            },
        },

        mounted() {
            this.revalidateGuid();
            if (this.guid == undefined || this.guid == null || this.guid == "") {
                this.$router.push({ path: "/login" });
            }

            setTimeout( () => {
                this.adjustTimeline();
            }, 500);

            window.addEventListener("resize", this.adjustTimeline);
        }
    })
</script>

<style>
    .v-carousel__controls__item {
        border: none !important;
        color: white !important;
    }
</style>